// Scroll Magic
function scrolltime() {
    //DEV MODE
    var DEVMODE = false;
    // Setup Controller
    var sm_cont_global = new ScrollMagic.Controller({
        //container: 'window', // main container for scrolling
        //vertical: true/false, // false = horizontal
        //globalSceneOptions: { // options for every scene
        //    triggerHook: 'onLeave'
        //},
        //loglevel: 3
    });
    //Sticky Logo
    var scene = new ScrollMagic.Scene({
            triggerElement: "#s-logo", // point of execution
            duration: 0, // pin element for the window height - 1
            triggerHook: 0, // don't trigger until #pinned-trigger1 hits the top of the viewport
            reverse: true // allows the effect to trigger when scrolled in the reverse direction
        })
        .setPin("#s-logo")
        .addTo(sm_cont_global);
    if (DEVMODE) { scene.addIndicators({ name: "2 (duration: 0)" }) };

    //Sticky Nav
    /*new ScrollMagic.Scene({
            triggerElement: "#s-nav-trigger",
            triggerHook: 0,
            })
        .setClassToggle("#app", "stickynav") // add class toggle
        .addIndicators({ name: "Nav" }) // add indicators (requires plugin)
        .addTo(controller);*/

    //Fade In Universal
    if ($(".scroll-fade").length) {
        $('.scroll-fade').each(function() {
            var sm_cont_1 = new ScrollMagic.Controller({});
            var sm_s = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: -250 })
                .setClassToggle(this, "in") // add class toggle
                .addTo(sm_cont_1);
            if (DEVMODE) { sm_s.addIndicators({ name: "Fade", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //Bounce In Universal
    var bouncetween = TweenMax.staggerFromTo(".scroll-bounce span", 1, { top: "-300px" }, { top: "0px", ease: Bounce.easeOut }, 0);
    if ($(".scroll-bounce").length) {
        $('.scroll-bounce').each(function() {
            var sm_cont_2 = new ScrollMagic.Controller({});
            var sm_s = new ScrollMagic.Scene({ triggerElement: ".scroll-bounce", triggerHook: "onEnter", offset: 200 })
                .setTween(bouncetween) // add class toggle
                .addTo(sm_cont_2);
            if (DEVMODE) { sm_s.addIndicators({ name: "Bounce In", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) }

        });
    }

    //Greyscale Fade In Universal
    if ($(".scroll-greyscale").length) {
        $('.scroll-greyscale').each(function() {
            var sm_cont_3 = new ScrollMagic.Controller({});
            var sm_s = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 250 })
                .setClassToggle(this, "in") // add class toggle
                .addTo(sm_cont_3);
            if (DEVMODE) { sm_s.addIndicators() }

        });
    }

    //Parallax Header BG Universal
    if ($("section").length) {
        $('section').each(function() {
            var sm_cont_4 = new ScrollMagic.Controller({});
            var sm_s = new ScrollMagic.Scene({ duration: $(window).height() - 60 })
                .setTween(new TimelineMax().add([
                    TweenMax.to("section .top", 1, { backgroundPositionY: "40%", ease: Linear.easeNone }),
                    TweenMax.to("section .top .wrap", 1, { top: "70%", opacity: 0, ease: Linear.easeNone })
                ]))
                .addTo(sm_cont_4);
        });
    }

    if ($("#parallax-1").length) {
        var sm_cont_p1 = new ScrollMagic.Controller({
            globalSceneOptions: { triggerHook: "onEnter", duration: "200%" }
        });
        var parallaxheadtween1 = TweenMax.to("#parallax-1 > .p_image", 1, { y: '-40%', autoAlpha: 1, ease: Power0.easeNone });
        var pscene1 = new ScrollMagic.Scene({ triggerElement: "#parallax-1" })
            .setTween(parallaxheadtween1)
            .addTo(sm_cont_p1);
        if (DEVMODE) { pscene1.addIndicators({ name: "parallax Section", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) }
    }
    if ($("#parallax-2").length) {
        var sm_cont_p2 = new ScrollMagic.Controller({
            globalSceneOptions: { triggerHook: "onEnter", duration: "200%" }
        });
        var parallaxheadtween2 = TweenMax.to("#parallax-2 > .p_image", 1, { y: '-60%', autoAlpha: 1, ease: Power0.easeNone });
        var pscene2 = new ScrollMagic.Scene({ triggerElement: "#parallax-2" })
            .setTween(parallaxheadtween2)
            .addTo(sm_cont_p2);
        if (DEVMODE) { pscene2.addIndicators({ name: "parallax Section", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) }
    }
    if ($("#parallax-3").length) {
        var sm_cont_p3 = new ScrollMagic.Controller({
            globalSceneOptions: { triggerHook: "onEnter", duration: "200%" }
        });
        var parallaxheadtween3 = TweenMax.to("#parallax-3 > .parallax-video", 1, { y: '-30%', autoAlpha: 1, ease: Power0.easeNone });
        var pscene3 = new ScrollMagic.Scene({ triggerElement: "#parallax-3" })
            .setTween(parallaxheadtween3)
            .addTo(sm_cont_p3);
        if (DEVMODE) { pscene3.addIndicators({ name: "parallax Section", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) }
    }
};
$(document).ready(function() {
    scrolltime();
    console.log("scroll init");
});